<template>
  <div>
    <div class="logobox">
      <router-link class="left exit" to="/">
      <IconComponent name="arrowLeftIcon"/>
      <span v-if="lang=='uz'">Chiqish</span>
      <span v-if="lang=='ru'">Выход</span>
      <span v-if="lang=='en'">Exit</span>
    </router-link>
      <div class="logo2">
        <IconComponent name="logo" />
      </div>
      <LoadingSpinner v-if="loading" />
    </div>
    <div class="video_page">
    <h2 class="main_title qanaqadir " v-if="lang=='uz'">Taqdimot</h2>
    <h2 class="main_title qanaqadir " v-else-if="lang==='ru'">Презентация</h2>
    <h2 class="main_title qanaqadir " v-else>Presentation Slide</h2>
    <div id="slider" :class="{ fullscreen: isFullscreen }">
      <div class="slides">
        <div class="slider-item">
          <img :src="slide" alt="Slide Image" @load="handleImageLoad" v-for="(slide, index) in slides" :key="index"
            :style="index + 1 == active ? 'z-index:100' : 'z-index:0'" />
        </div>
      </div>
      <span class="prev" @click="move(-1)">
        <IconComponent name="prewIcon" aria-hidden="true" />
      </span>
      <span class="next" @click="move(1)">
        <IconComponent name="nextIcon" aria-hidden="true" />
      </span>
      <ul class="dots">
        <li v-for="(slide, index) in slides" :class="{ active: index + 1 === active }" @click="jump(index + 1)"
          :key="index"></li>
      </ul>
      <button class="zoom" @click="toggleFullscreen">
        {{ isFullscreen ? 'Exit Fullscreen' : 'Zoom' }}
      </button>
    </div>
    <div class="langbox">
      <a :href="pdfPath" download class="download left">
        <span v-if="lang=='uz'">Yuklash</span>
        <span v-if="lang=='ru'">Скачать</span>
        <span v-if="lang=='en'">Download</span>
        <IconComponent name="downloadIcon" />
      </a>
      <div class="lang">
        <div class="left" :class="{ active: lang === 'uz' }" @click="setLang('uz')">
          Uzb
          <IconComponent name="uz" />
        </div>
        <div class="left" :class="{ active: lang === 'en' }" @click="setLang('en')">
          Eng
          <IconComponent name="en" />
        </div>
        <div class="left" :class="{ active: lang === 'ru' }" @click="setLang('ru')">
          Rus
          <IconComponent name="ru" />
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import IconComponent from '../components/icons.vue';
import LoadingSpinner from '../components/LoadSpinner.vue';

export default {
  name: 'SliderPage',
  components: {
    IconComponent,
    LoadingSpinner,
  },
  data() {
    return {
      lang: 'en',
      active: 1,
      isFullscreen: false,
      loading: true,
      imagesToLoad: [],
      loadedImages: 0,
    };
  },
  computed: {
    slides() {
      if (this.lang === 'uz') {
        return [
          require('../assets/images/UZ-1.jpg'),
          require('../assets/images/UZ-2.jpg'),
          require('../assets/images/UZ-3.jpg'),
          require('../assets/images/UZ-4.jpg'),
          require('../assets/images/UZ-5.jpg'),
          require('../assets/images/UZ-6.jpg'),
          require('../assets/images/UZ-7.jpg'),
          require('../assets/images/UZ-8.jpg'),
          require('../assets/images/UZ-9.jpg'),
        ];
      } else if (this.lang === 'en') {
        return [
          require('../assets/images/ENG-1.jpg'),
          require('../assets/images/ENG-2.jpg'),
          require('../assets/images/ENG-3.jpg'),
          require('../assets/images/ENG-4.jpg'),
          require('../assets/images/ENG-5.jpg'),
          require('../assets/images/ENG-6.jpg'),
          require('../assets/images/ENG-7.jpg'),
          require('../assets/images/ENG-8.jpg'),
          require('../assets/images/ENG-9.jpg'),
        ];
      } else {
        return [
          require('../assets/images/RUS-1.jpg'),
          require('../assets/images/RUS-2.jpg'),
          require('../assets/images/RUS-3.jpg'),
          require('../assets/images/RUS-4.jpg'),
          require('../assets/images/RUS-5.jpg'),
          require('../assets/images/RUS-6.jpg'),
          require('../assets/images/RUS-7.jpg'),
          require('../assets/images/RUS-8.jpg'),
          require('../assets/images/RUS-9.jpg'),
        ];
      }
    },
    pdfPath() {
      return `/${this.lang}.pdf`;
    },
  },
  methods: {
    move(amount) {
      let newActive;
      const newIndex = this.active + amount;
      if (newIndex > this.slides.length) newActive = 1;
      if (newIndex === 1) newActive = this.slides.length;
      this.active = newActive || newIndex;
    },
    jump(index) {
      this.active = index;
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
    },
    setLang(lang) {
      this.lang = lang;
    },
    handleKeydown(event) {
      if (event.key === 'ArrowRight') {
        this.move(1);
      } else if (event.key === 'ArrowLeft') {
        this.move(-1);
      }
    },
    handleImageLoad() {
      this.loadedImages++;
      if (this.loadedImages === this.imagesToLoad.length) {
        this.loading = false;
      }
    },
    preloadImages(images) {
      this.loadedImages = 0;  // Reset loadedImages counter
      this.loading = true;    // Show loading spinner
      images.forEach((src) => {
        const img = new Image();
        img.src = src;
        img.onload = this.handleImageLoad;
      });
    },
  },
  watch: {
    lang() {
      this.imagesToLoad = this.slides.slice();
      this.preloadImages(this.imagesToLoad);
    },
  },
  mounted() {
    this.imagesToLoad = this.slides.slice();
    this.preloadImages(this.imagesToLoad);
  },
};
</script>

<style scoped>
.slider-item {
  position: relative;
}

.slider-item img {
  position: absolute;
}
</style>
