<template>
 <div>
  <div class="logo">
    <IconComponent name="logo"/>
</div>
  <div class="home" @click.self="resetActiveStatus">
    <div class="folders" >
      <div class="folder" 
      v-for="data in data_folder" 
      :key="data.name" 
      :id="data.id" 
      @dblclick="navigate(data.link)" 
      @click="toggleStatus(data)"
      :class="{ status: data.isActive }">

        <div class="icon" >
          <img src="../assets/images/folder.png" alt="">
        </div>
        <p>{{data.name}}</p>
      </div>
      <a class="folder" target="_blank" href="https://www.figma.com/proto/BtMhpB57b1GONaIsIG1irM/%D0%9E%D0%B1%D1%83%D1%87%D0%B0%D1%8E%D1%89%D0%B8%D0%B9-%D0%BC%D0%B0%D1%82%D0%B5%D1%80%D0%B8%D0%B0%D0%BB?page-id=0%3A1&node-id=44-777&viewport=672%2C234%2C0.04&t=oatAUa25v0plDCEv-8&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=44%3A777&hide-ui=1">
        <img src="../assets/images/link.png" alt="">
        <p>Training File</p>
      </a>
    </div>
  </div>
 </div>
</template>

<script>
import IconComponent from '../components/icons.vue'
export default {
  name: "HomePage",
  components:{
    IconComponent
  },
  data() {
    return {
      data_folder: [
        {
          name: "Promotional Video",
          link:"/video",
          isActive: false
        },
        {
          name: "Presentation",
          link:"/pdf",
          isActive: false
        },
        {
          name: "Elevator Pitch",
          link:"/elevator",
          isActive: false
        },
        {
          name: "MVP Demo",
          link:"/platform",
          isActive: false
        },
      ],
    };
  },
  methods: {
    navigate(link) {
      this.$router.push(link);
    },
    toggleStatus(item) {
        this.data_folder.forEach(data => data.isActive = false);
        item.isActive = true;
    },
    resetActiveStatus() {
      this.data_folder.forEach(data =>{ 
        if(data.isActive){
          data.isActive= false
        }
    })
    }
  }
};
</script>

<style>
.home {
  
  padding: 20px 30px;
  max-width: 1280px;
  height: 75vh;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}
.logo{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.logo>div>svg{
  height: 120px;
}
.folders {
  display: flex;
  justify-content: space-between;
}
.folder{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 200px;
  width: 180px;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s, border-radius 0.3s;

}
.folder > p {
  text-align: center;
  user-select: none;
}
.folder>img{
  width: 140px;
  height: 140px;
  padding: 20px;
  
}
.icon img {
  width: 140px;
}
.status {
  border-radius: 10px;
  background-color: #ccc;
}
.gap-20px{
  gap: 20px;
}
</style>
