<template>
  <div>
    <div class="logobox">
      <router-link class="left exit" to="/">
      <IconComponent name="arrowLeftIcon"/>
      <span v-if="lang=='uz'">Chiqish</span>
      <span v-if="lang=='ru'">Выход</span>
      <span v-if="lang=='en'">Exit</span>
    </router-link>
    <div class="logo2">
      <IconComponent name="logo"/>
    </div>
    </div>
    <div class="video_page">
      <h2 class="main_title">{{ lang==='ru'?'Рекламный ролик':'Promotional Video' }}</h2>
      <div class="video-container">
        <img v-if="!isPlaying" src="../assets/images/bg.jpg" alt="Video background" @click="playVideo">
        <video v-show="isPlaying" :src="currentVideoSrc" controls ref="video" @canplay="onCanPlay" @waiting="onWaiting" preload="metadata"></video>
        <div v-if="isLoading" class="loading-spinner"></div>
      </div>
    </div>

    <div class="langbox">
      <a :href="currentVideoSrc" download class="download left">
        <span v-if="lang=='uz'">Yuklash</span>
        <span v-if="lang=='ru'">Скачать</span>
        <span v-if="lang=='en'">Download</span>
         <IconComponent name="downloadIcon" />
      </a>
      <div class="lang">
        <!-- <div
          class="left"
          :class="{ active: lang === 'uz' }"
          @click="setLang('uz')"
        >
          Uzb <IconComponent name="uz" />
        </div> -->
        <div
          class="left"
          :class="{ active: lang === 'en' }"
          @click="setLang('en')"
        >
          Eng <IconComponent name="en" />
        </div>
        <div
          class="left"
          :class="{ active: lang === 'ru' }"
          @click="setLang('ru')"
        >
          Rus <IconComponent name="ru" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from '../components/icons.vue'
export default {
  name: "VideoPage",
  components: {
    IconComponent
  },
  data() {
    return {
      isPlaying: false,
      isLoading: false,
      lang: 'en',
      currentVideoSrc: 'https://video.iotics.uz/video/FullHD_Eng.mp4',
    };
  },
  methods: {
    playVideo() {
      const video = this.$refs.video;
      video.muted = false;
      this.isPlaying = true;
      this.isLoading = true;
      video.play().catch(error => {
        console.log('Video ijro etishda xato:', error);
      });
    },
    onCanPlay() {
      this.isLoading = false;
    },
    onWaiting() {
      this.isLoading = true;
    },
    setLang(language) {
      this.lang = language;
      if(language == 'en'){
        this.currentVideoSrc = `https://video.iotics.uz/video/FullHD_Eng.mp4`; 
      }else if(language == 'ru'){
        this.currentVideoSrc = `https://video.iotics.uz/video/1-FullHD_RU.mp4`; 
      }
      if (this.isPlaying) {
        const video = this.$refs.video;
        video.pause();
        video.load();
        this.playVideo();
      }
    },
  },
  
};
</script>

<style>
/* Qo'shimcha stil sozlamalari */
</style>
