<template>
 <section class="service-section position-relative">
        <figure class="service-image mb-0">
          <img :src="require('./assets/images/service-image.png')" class="img-fluid" alt="">
        </figure>        
        <router-view></router-view>
    <!-- <Header/> -->
  </section>
</template>
<script>
// import Header from './components/Header.vue';
  export default {
    
    components:{
      // Header
    }
  }
</script>
<style>
.service-section{
  background-color: #eafcfe;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 99.6vh;
}
.service-image{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  height: 100%;
}
.img-fluid{
  width: 100%;
  height: 100% !important;
  z-index: 0 !important;
  user-select: none;
  opacity: 0.3;
}
</style>