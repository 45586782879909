import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import Video from '../views/Video.vue';
import Slider from '../views/Slider.vue';
import Elevator from '../views/Elevator.vue'
import Mwp from '@/views/Mwp.vue';
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/pdf',
    name: 'Slider',
    component: Slider
  },
  {
    path: '/elevator',
    name: 'Elevator',
    component: Elevator
  },
  {
    path: '/platform',
    name: 'Mvp',
    component: Mwp
  },
  {
    path: '/:catchAll(.*)',
    name: 'Home',
    component:  Home // NotFound sahifangiz bo'lishi kerak
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;