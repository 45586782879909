<template>
    <div>
     <div class="logobox">
      <router-link class="left exit" to="/">
      <IconComponent name="arrowLeftIcon"/>
      <span v-if="lang=='uz'">Chiqish</span>
      <span v-if="lang=='ru'">Выход</span>
      <span v-if="lang=='en'">Exit</span>
    </router-link>
      <div class="logo2">
        <IconComponent name="logo" />
      </div>
     </div>
      <div class="mvp">
        <h2 class="main_title"> MVP Demo</h2>
        <div class="description">{{ langs[lang].message }}</div>
      </div>
      <div class="apps">
        <a href="https://video.iotics.uz/download/demo.exe" download class="mongo">
          <img src="../assets/images/ll.svg" alt="">
          <p>MVP Version</p>
        </a>
      </div>
    </div>
      <div class="langbox">
        <a href="https://video.iotics.uz/download/demo.exe" download class="download left">
          <span v-if="lang=='uz'">Yuklash</span>
        <span v-if="lang=='ru'">Скачать</span>
        <span v-if="lang=='en'">Download</span>
            <IconComponent name="downloadIcon" />
        </a>
        <div class="lang">
          <div class="left" :class="{ active: lang === 'uz' }" @click="setLang('uz')">
            Uzb
            <IconComponent name="uz" />
          </div>
          <div class="left" :class="{ active: lang === 'en' }" @click="setLang('en')">
            Eng
            <IconComponent name="en" />
          </div>
          <div class="left" :class="{ active: lang === 'ru' }" @click="setLang('ru')">
            Rus
            <IconComponent name="ru" />
          </div>
        </div>
    </div>
  </template>
<script>
import IconComponent from '../components/icons.vue';

export default {
  name: "MvpPage",
  components: {
    IconComponent
  },
  data() {
    return {
      lang: 'en',
      langs: {
        ru: {
          message: "Эта версия системы IOTICS, выпущена для демонстрации возможности и функционала системы. В этой версии системы имитированы абстрактные счетчики электроэнергии и данные генерируется самой системой."
        },
        en: {
          message: "This version of the IOTICS software is released to demonstrate the capability and functionality of the system. In this version of the system abstract electricity meters are simulated and data is generated by the system."
        },
        uz: {
          message: "IOTICS dasturiy ta'minotining bu versiyasi, tizimning to'liq imkoniyatlarini va funksionalini ko'rsatib berish uchun yaratilgan. Bu tizimda abstrakt elektr energiyasini hisoblash qurilmalari imitasiya qilingan va ma'lumotlar tizim tarafidan yaratiladi."
        }
      },
    };
  },
  methods: {
    setLang(newLang) {
      this.lang = newLang;
    }
  }
};
</script>
<style scoped>

.mvp {
max-width: 60%;
min-width: 60%;
margin:  auto 0;
display: flex;
flex-direction: column;
gap: 60px;
position: relative;
margin: 0 auto;
}
.mvp>h2{
font-size: 48px;
padding-left:60px !important;
}
.mvp>img {
width: 140px;
height: 140px;
}

.description {
background: rgb(227 16 55 / 27%);
box-shadow: (0px 4px 12px #D8EDF2);
border-radius: 18px;
width: 100%;
margin: 0 auto;
}

.description {
color: #041B39;
display: flex;
justify-content: center;
align-items: center;
padding: 20px;
}
.apps{
width: 100%;
display: flex;
justify-content: center;
gap: 80px;
margin: 40px auto ;
position: relative;
}
.mongo {
width: 140px;
height: 140px;
display: flex;
flex-direction: column;
align-items: center;
gap: 20px;
}

.mongo>img {
width: 100%;
height: 100%;
}
.langbox{
  padding-top: 40px;
}
</style>